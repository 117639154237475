import React from "react"
import { makeStyles } from "@material-ui/core/styles"
import Modal from "@material-ui/core/Modal"
import moment from "moment"

const useStyles = makeStyles((theme) => ({
  root: {
    height: 970,
    flexGrow: 1,
    transform: "translateZ(0)",
    // The position fixed scoping doesn't work in IE 11.
    // Disable this demo to preserve the others.
    "@media all and (-ms-high-contrast: none)": {
      display: "none",
    },
  },
  modal: {
    display: "flex",
    padding: theme.spacing(1),
    alignItems: "center",
    justifyContent: "center",
  },
  paper: {
    width: "100%",
    backgroundColor: theme.palette.background.paper,
    border: "2px solid #000",
    boxShadow: theme.shadows[5],
    padding: theme.spacing(2, 4, 3),
  },
}))

export default function ModalSimples(props) {
  console.log(props)
  const classes = useStyles()
  const rootRef = React.useRef(null)
  return (
    <div id="printable">
      <img src="https://cdn.anapolis.go.gov.br/img/logos/logo_azul.png" />
      <br />
      <div className="Modal-Container" ref={rootRef}>
        <Modal
          disablePortal
          disableEnforceFocus
          disableAutoFocus
          open
          aria-labelledby="server-modal-title"
          aria-describedby="server-modal-description"
          className={classes.modal}
          container={() => rootRef.current}
        >
          <div className="Modal-Documentos">
            <h2 id="server-modal-title">{props.titulo}</h2>
            <p id="server-modal-description">
              {props.descricao}&nbsp;<b>{props.inscricao.protocolo}</b>
            </p>
            <p>
              Inscrição realizada em{" "}
              <strong>
                {moment(props.inscricao.createdAt).format("DD/MM/YYYY")} às{" "}
                {moment(props.inscricao.createdAt).format("HH:mm")}
              </strong>{" "}
              para concorrer a vaga de {props.inscricao.cargo}.
            </p>
            <p>
              Volte para a tela inicial e faça o upload dos documentos clicando
              no botão <b>Consultar inscrição</b> usando o seu protocolo.
            </p>
            {/* <div className="Documentos-Entregues flex horizontal">
              <div className="Documentos-Entregues-Bloco-1 flex vertical">
                <p>
                  <b>Currículo:</b> {props.inscricao.curriculo_original}
                </p>
                <p>
                  <b>Carteira de Trabalho, PIS, NIS OU PASEP:</b>{" "}
                  {props.inscricao.ctps_original}
                </p>
                <p>
                  <b>Diploma:</b> {props.inscricao.diploma_original}
                </p>
                <p>
                  <b>Identidade ou CNH:</b>{" "}
                  {props.inscricao.identidade_cnh_original}
                </p>
                <p>
                  <b>Comprovante de situação Cadastral no CPF:</b>{" "}
                  {props.inscricao.cpf_doc_original}
                </p>
                <p>
                  <b>Comprovante de residência:</b>{" "}
                  {props.inscricao.comprovante_residencia_original}
                </p>
                <p>
                  <b>Registro do conselho de classe:</b>{" "}
                  {props.inscricao.rcc_original}
                </p>
                <p>
                  <b>Certidão de adimplência:</b>{" "}
                  {props.inscricao.adimplencia_original}
                </p>
              </div>
              <div className="Documentos-Entregues-Bloco-2 flex vertical">
                <p>
                  <b>Certidão Negativa de Comissão Ética:</b>{" "}
                  {props.inscricao.nada_consta_original}
                </p>
                <p>
                  <b>Certidão de regularidade Federal:</b>{" "}
                  {props.inscricao.regularidade_federal_original}
                </p>
                <p>
                  <b>Certidão de regularidade Fazenda Estadual:</b>{" "}
                  {props.inscricao.regularidade_fazenda_estadual_original}
                </p>
                <p>
                  <b>Certidão de regularidade Fazenda Municipal:</b>{" "}
                  {props.inscricao.regularidade_fazenda_municipal_original}
                </p>
                <p>
                  <b>Certidão negativa de débitos trabalhistas:</b>{" "}
                  {props.inscricao.regularidade_debito_trabalhista_original}
                </p>
              </div>
            </div>
            Sua ordem de registro é {props.inscricao.ordem}º */}
          </div>
        </Modal>
      </div>
    </div>
  )
}
