import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import InputLabel from '@material-ui/core/InputLabel';
import FormHelperText from '@material-ui/core/FormHelperText';
import FormControl from '@material-ui/core/FormControl';
import Select from '@material-ui/core/Select';
import NativeSelect from '@material-ui/core/NativeSelect';

const useStyles = makeStyles((theme) => ({

    selectEmpty: {
        marginTop: theme.spacing(2),
    },
}));

export default function NativeSelects(props) {
    const classes = useStyles();

    return (
        <div className='Select-Simples'>
            <FormControl variant="outlined">
                <InputLabel htmlFor="outlined-age-native-simple">{props.label}</InputLabel>
                <Select
                    native
                    value={props.value}
                    onChange={props.onChange}
                    label={props.label}
                    inputProps={{
                        name: `{props.label}`,
                        id: 'outlined-age-native-simple',
                    }}
                >
                    {
                        props.opcoes.map((opcao, idx) => (
                            <option key={idx} value={opcao.value}>{opcao.label}</option>
                        ))
                    }

                    {/* <option aria-label="None" value="" />
                    <option value={10}>Ten</option>
                    <option value={20}>Twenty</option>
                    <option value={30}>Thirty</option> */}
                </Select>
            </FormControl>
        </div>
    );
}