import React from 'react'
import './button.css'

const Button = ({ type, onClick, label, icon, disabled }) => (
    <div className='Button'>
        <button onClick={onClick}
            className={`button button-${disabled == 'true' ? 'disabled' : type}`}
        >
            <i className={icon}></i> {label}
        </button>
    </div>
)

export default Button