import React from "react"
import { BrowserRouter, Route, Switch, Redirect } from "react-router-dom"
import { getToken } from "./services/auth"
import App from "./App.js"
import Login from "./containers/Login"
import AlterarSenhaAdm from "./containers/Inscricoes/Adm/alterarSenha"
import UsuariosAdm from "./containers/Inscricoes/Adm/usuarios"
import UsuariosCreateAdm from "./containers/Inscricoes/Adm/novoUsuario"
import InscricoesAdm from "./containers/Inscricoes/Adm/inscricoes"
import ShowInscricaoAdm from "./containers/Inscricoes/Adm/showInscricao"
import NovaInscricao from "./containers/Inscricoes/novaInscricao"
import BuscarInscricao from "./containers/Inscricoes/buscarInscricao"

const PrivateRoute = ({ component: Component, ...rest }) => (
  <Route
    {...rest}
    render={(props) =>
      getToken() !== null ? (
        <Component {...props} />
      ) : (
        <Redirect
          to={{ pathname: "/login", state: { from: props.location } }}
        />
      )
    }
  />
)

const Routes = () => (
  <BrowserRouter>
    <Switch>
      <Route exact path="/" component={() => <App />} />
      <PrivateRoute
        exact
        path="/adm/usuarios/alterarSenha"
        component={() => <AlterarSenhaAdm />}
      />
      <PrivateRoute
        exact
        path="/adm/usuarios"
        component={() => <UsuariosAdm />}
      />
      <PrivateRoute
        exact
        path="/adm/usuarios/create"
        component={() => <UsuariosCreateAdm />}
      />
      <PrivateRoute
        exact
        path="/adm/inscricoes"
        component={() => <InscricoesAdm />}
      />
      <PrivateRoute
        exact
        path="/adm/inscricoes/detalhes/:id"
        component={() => <ShowInscricaoAdm />}
      />
      <Route exact path="/login" component={() => <Login />} />
      {/* <Route exact path="/inscricao" component={() => <NovaInscricao />} /> */}
      <Route exact path="/protocolo" component={() => <BuscarInscricao />} />
    </Switch>
  </BrowserRouter>
)

export default Routes
