import React from 'react';
import TextField from '@material-ui/core/TextField';
import { makeStyles } from '@material-ui/core/styles';

const useStyles = makeStyles((theme) => ({
    root: {
        '& .MuiTextField-root': {
            margin: theme.spacing(1),
            width: '90% !important',
        },
    },
}));

export default function InputSimples(props) {
    const classes = useStyles();
    return (
        <form noValidate autoComplete="off">

            <TextField
                error={props.erro}
                helperText={props.erro ? props.helpText : ''}
                id={props.id}
                type={props.type}
                label={props.label}
                value={props.value}
                name={props.name}
                className={classes.root}
                onChange={props.onChange}
                variant="outlined"
                InputLabelProps={{
                    shrink: true
                }}
            />
        </form>
    );
}