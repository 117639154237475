import React, { useEffect } from "react"
import axios from "axios"
import { apiURL } from "../../services/api"
import { makeStyles } from "@material-ui/core/styles"
import InputLabel from "@material-ui/core/InputLabel"
import FormControl from "@material-ui/core/FormControl"
import Select from "@material-ui/core/Select"
import { Link } from "react-router-dom"
import { api } from "../../services/api"
import { ToastContainer, toast } from "react-toastify"
import Progress from "../../components/Progress"

import "react-toastify/dist/ReactToastify.css"

const useStyles = makeStyles((theme) => ({
  formControl: {
    margin: theme.spacing(1),
    minWidth: 120,
  },
  select: {
    width: "80%",
  },
  selectEmpty: {
    marginTop: theme.spacing(2),
  },
}))

export default function ShowInscricao(props) {
  const messageSucess = (message) => toast.success(message)
  const messageError = (message) => toast.error(message)

  const classes = useStyles()
  const [loading, setLoading] = React.useState(false)
  const [tipoDocumento, setTipoDocumento] = React.useState(null)
  const [file, setFile] = React.useState(null)
  const [docsEnviados, setDocsEnviados] = React.useState(null)

  useEffect(async () => {
    var cpf2 = props.inscricao.cpf
    cpf2 = cpf2.replace(".", "")
    cpf2 = cpf2.replace(".", "")
    cpf2 = cpf2.replace("-", "")

    await axios
      .get(`https://api.anapolis.go.gov.br/apiupload/upload?cpf=${cpf2}`)
      .then((docs) => {
        setDocsEnviados(docs.data.result)
      })
      .catch((e) => console.log(e))
  }, [])
  var cpf2 = props.inscricao.cpf
  cpf2 = cpf2.replace(".", "")
  cpf2 = cpf2.replace(".", "")
  cpf2 = cpf2.replace("-", "")

  let tiposEnviados = (docsEnviados ?? []).map((item) => {
    return item.tipo
  })

  const documentsNecessary = [
    {
      name: "",
      label: "",
    },
    {
      name: "CURRICULO",
      label: "Currículo (Item 6.1.a)",
      disabled: tiposEnviados.indexOf("CURRICULO") > -1 ? true : false,
    },
    {
      name: "CTPS",
      label: "Carteira de Trabalho, PIS, NIS OU PASEP (Item 6.1.a.1)",
      disabled: tiposEnviados.indexOf("CTPS") > -1 ? true : false,
    },
    {
      name: "DIPLOMA",
      label: "Diploma (Item 6.1.a.2)",
      disabled: tiposEnviados.indexOf("DIPLOMA") > -1 ? true : false,
    },
    // {
    //   name: "EXPSAUDEMENTAL",
    //   label:
    //     "Declaração de experiência Profissional em Saude Mental (Item 6.1.a3)",
    //   disabled: tiposEnviados.indexOf("EXPSAUDEMENTAL") > -1 ? true : false,
    // },
    {
      name: "IDCNH",
      label: "Identidade ou CNH (Item 6.1.b)",
      disabled: tiposEnviados.indexOf("IDCNH") > -1 ? true : false,
    },
    {
      name: "SITCPF",
      label: "Comprovante de Situação Cadastral no CPF (Item 6.1.c)",
      disabled: tiposEnviados.indexOf("SITCPF") > -1 ? true : false,
    },
    {
      name: "COMPRESIDENCIA",
      label: "Comprovante de residência (Item 6.1.d)",
      disabled: tiposEnviados.indexOf("COMPRESIDENCIA") > -1 ? true : false,
    },
    {
      name: "ANEXOI",
      label:
        "Anexo I (Declaração de Desimpedimento e Disponibilidade de Horário) (Item 6.1.e)",
      disabled: tiposEnviados.indexOf("ANEXOI") > -1 ? true : false,
    },
    {
      name: "ANEXOII",
      label:
        "Anexo II (Declaração de Conhecimento das Condições do Edital) (Item 6.1.f)",
      disabled: tiposEnviados.indexOf("ANEXOII") > -1 ? true : false,
    },
    {
      name: "RCC",
      label: "Registro do conselho de classe (Item 6.1.g)",
      disabled: tiposEnviados.indexOf("RCC") > -1 ? true : false,
    },
    {
      name: "ADIMPLENCIA",
      label: "Certidão de adimplência (Item 6.1.h)",
      disabled: tiposEnviados.indexOf("ADIMPLENCIA") > -1 ? true : false,
    },
    {
      name: "CNCE",
      label: "Certidão Negativa de Comissão Ética (Item 6.1.i)",
      disabled: tiposEnviados.indexOf("CNCE") > -1 ? true : false,
    },
    {
      name: "CRF",
      label: "Certidão de regularidade Federal (Item 6.1.j)",
      disabled: tiposEnviados.indexOf("CRF") > -1 ? true : false,
    },
    {
      name: "CRFE",
      label: "Certidão de regularidade Fazenda Estadual (Item 6.1.j)",
      disabled: tiposEnviados.indexOf("CRFE") > -1 ? true : false,
    },
    {
      name: "CRFM",
      label: "Certidão de regularidade Fazenda Municipal (Item 6.1.j)",
      disabled: tiposEnviados.indexOf("CRFM") > -1 ? true : false,
    },
    {
      name: "CNDT",
      label: "Certidão negativa de débitos trabalhistas (Item 6.1.j)",
      disabled: tiposEnviados.indexOf("CNDT") > -1 ? true : false,
    },
  ]

  const convertBase64 = (file) => {
    if (!file) {
      messageError("Selecione um arquivo.")
    } else {
      return new Promise((resolve, reject) => {
        const fileReader = new FileReader()
        fileReader.readAsDataURL(file)
        fileReader.onload = () => {
          resolve(fileReader.result)
        }
        fileReader.onerror = (error) => {
          reject(error)
        }
      })
    }
  }

  //CARREGAR ARQUIVO
  const handleChangeFile = async (e) => {
    var file = e.target.files[0] // accesing file
    setFile(file)
  }

  //   UPLOAD ARQUIVO NO BANCO
  const uploadFile = async () => {
    setLoading(true)
    const fileBase64 = await convertBase64(file)

    if (!tipoDocumento)
      return messageError("Selecione um tipo de documento a ser enviado.")
    await axios
      .post(
        `https://api.anapolis.go.gov.br/apiupload/upload?application=CREDSAUDE&idRegister=${props.inscricao.protocolo}&tipo=${tipoDocumento}&cpf=${cpf2}`,
        { image64: fileBase64 }
      )
      .then(async (res) => {
        if (
          documentsNecessary.filter((item) => item.disabled === false).length ==
          1
        ) {
          await api
            .put(
              `inscricoes/inscrito/gerarOrdem/${props.inscricao.id}?cargo=${props.inscricao.cargo}`
            )
            .then((res) => {
              setLoading(false)
              messageSucess("Arquivo salvo com sucesso!")
            })
            .catch((e) => {
              setLoading(false), console.log(e)
            })
        } else {
          setLoading(false)
          messageSucess(res.data.message)
        }

        setFile(null)
        setTipoDocumento(null)
        var cpf2 = props.inscricao.cpf
        cpf2 = cpf2.replace(".", "")
        cpf2 = cpf2.replace(".", "")
        cpf2 = cpf2.replace("-", "")

        await axios
          .get(`https://api.anapolis.go.gov.br/apiupload/upload?cpf=${cpf2}`)
          .then((docs) => {
            setLoading(false)
            setDocsEnviados(docs.data.result)
          })
          .catch((e) => console.log(e))
      })
      .catch((err) => messageError(err))
  }

  console.log(
    documentsNecessary.filter((item) => item.disabled === false).length
  )
  return (
    <div className="Show-Inscricao">
      <ToastContainer />

      <div style={{ fontSize: "1.2rem", margin: "15px" }}>
        <Link to="/">Voltar </Link>
      </div>
      <span>
        {props.inscricao.nome_completo}, sua inscrição para o cargo de{" "}
        {props.inscricao.cargo} se encontra <b>'{props.inscricao.situacao}'</b>
      </span>
      <div
        className="Show-Inscricao-Container flex"
        style={{
          marginTop: "50px",
          background: "#e8e6e6",
          width: "max-content",
          padding: "30px",
          borderRadius: "20px",
        }}
      >
        <div style={{ marginRight: "100px" }} className="flex vertical">
          <div className="flex horizontal">
            <p>
              <b>CPF:&nbsp;</b>
            </p>
            <p>{props.inscricao.cpf}</p>
          </div>
          <div className="flex horizontal">
            <p>
              <b>RG:&nbsp;</b>
            </p>
            <p>{props.inscricao.rg}</p>
          </div>
          <div className="flex horizontal">
            <p>
              <b>Data de nascimento:&nbsp;</b>
            </p>
            <p>{props.inscricao.data_nascimento}</p>
          </div>
          <div className="flex horizontal">
            <p>
              <b>Nome da mãe:&nbsp;</b>
            </p>
            <p>{props.inscricao.nome_mae}</p>
          </div>
          <div className="flex horizontal">
            <p>
              <b>CEP:&nbsp;</b>
            </p>
            <p>{props.inscricao.cep}</p>
          </div>
          <div className="flex horizontal">
            <p>
              <b>Endereço:&nbsp;</b>
            </p>
            <p>
              {props.inscricao.logradouro}, {props.inscricao.numero},{" "}
              {props.inscricao.complemento}, {props.inscricao.bairro},{" "}
              {props.inscricao.cidade} - {props.inscricao.uf}
            </p>
          </div>
          <div className="flex horizontal">
            <p>
              <b>E-mail:&nbsp;</b>
            </p>
            <p>{props.inscricao.email}</p>
          </div>
          <div className="flex horizontal">
            <p>
              <b>Telefone:&nbsp;</b>
            </p>
            <p>{props.inscricao.telefone}</p>
          </div>
          <div className="flex horizontal">
            <p>
              <b>Cargo:&nbsp;</b>
            </p>
            <p>{props.inscricao.cargo}</p>
          </div>
          <div className="flex horizontal">
            <p>
              <b>Protocolo:&nbsp;</b>
            </p>
            <p>{props.inscricao.protocolo}</p>
          </div>
        </div>
        <div className="Documentos flex vertical">
          {(docsEnviados ?? []).map((doc) => (
            <div>
              {/* CURRICULO */}
              {doc.tipo === "CURRICULO" && (
                <div className="flex horizontal">
                  <p>
                    <b>Curriculo:&nbsp;</b>
                  </p>
                  <a
                    href={`https://api.anapolis.go.gov.br/apiupload/cidadao/${doc.cpf}/${doc.file}`}
                    target="_blanc"
                  >
                    <i style={{ fontSize: "2rem" }} class="fas fa-file-pdf"></i>
                  </a>
                </div>
              )}
              {/* DIPLOMA */}
              {doc.tipo === "DIPLOMA" && (
                <div className="flex horizontal">
                  <p>
                    <b>Diploma:&nbsp;</b>
                  </p>
                  <a
                    href={`https://api.anapolis.go.gov.br/apiupload/cidadao/${doc.cpf}/${doc.file}`}
                    target="_blanc"
                  >
                    <i style={{ fontSize: "2rem" }} class="fas fa-file-pdf"></i>
                  </a>
                </div>
              )}
              {/* CTPS */}
              {doc.tipo === "CTPS" && (
                <div className="flex horizontal">
                  <p>
                    <b>CTPS:&nbsp;</b>
                  </p>
                  <a
                    href={`https://api.anapolis.go.gov.br/apiupload/cidadao/${doc.cpf}/${doc.file}`}
                    target="_blanc"
                  >
                    <i style={{ fontSize: "2rem" }} class="fas fa-file-pdf"></i>
                  </a>
                </div>
              )}
              {/* Identidade ou CNH */}
              {doc.tipo === "IDCNH" && (
                <div className="flex horizontal">
                  <p>
                    <b>Identidade ou CNH:&nbsp;</b>
                  </p>
                  <a
                    href={`https://api.anapolis.go.gov.br/apiupload/cidadao/${doc.cpf}/${doc.file}`}
                    target="_blanc"
                  >
                    <i style={{ fontSize: "2rem" }} class="fas fa-file-pdf"></i>
                  </a>
                </div>
              )}
              {/* Documento de CPF */}
              {doc.tipo === "SITCPF" && (
                <div className="flex horizontal">
                  <p>
                    <b>Documento de CPF:&nbsp;</b>
                  </p>
                  <a
                    href={`https://api.anapolis.go.gov.br/apiupload/cidadao/${doc.cpf}/${doc.file}`}
                    target="_blanc"
                  >
                    <i style={{ fontSize: "2rem" }} class="fas fa-file-pdf"></i>
                  </a>
                </div>
              )}
              {/* Comprovante de residência */}
              {doc.tipo === "COMPRESIDENCIA" && (
                <div className="flex horizontal">
                  <p>
                    <b>Comprovante de residência:&nbsp;</b>
                  </p>
                  <a
                    href={`https://api.anapolis.go.gov.br/apiupload/cidadao/${doc.cpf}/${doc.file}`}
                    target="_blanc"
                  >
                    <i style={{ fontSize: "2rem" }} class="fas fa-file-pdf"></i>
                  </a>
                </div>
              )}
              {/* AnexoI */}
              {doc.tipo === "ANEXOI" && (
                <div className="flex horizontal">
                  <p>
                    <b>AnexoI:&nbsp;</b>
                  </p>
                  <a
                    href={`https://api.anapolis.go.gov.br/apiupload/cidadao/${doc.cpf}/${doc.file}`}
                    target="_blanc"
                  >
                    <i style={{ fontSize: "2rem" }} class="fas fa-file-pdf"></i>
                  </a>
                </div>
              )}
              {/* AnexoII */}
              {doc.tipo === "ANEXOII" && (
                <div className="flex horizontal">
                  <p>
                    <b>AnexoII:&nbsp;</b>
                  </p>
                  <a
                    href={`https://api.anapolis.go.gov.br/apiupload/cidadao/${doc.cpf}/${doc.file}`}
                    target="_blanc"
                  >
                    <i style={{ fontSize: "2rem" }} class="fas fa-file-pdf"></i>
                  </a>
                </div>
              )}
              {/* Registro Conselho de Classe */}
              {doc.tipo === "RCC" && (
                <div className="flex horizontal">
                  <p>
                    <b>Registro Conselho de Classe:&nbsp;</b>
                  </p>
                  <a
                    href={`https://api.anapolis.go.gov.br/apiupload/cidadao/${doc.cpf}/${doc.file}`}
                    target="_blanc"
                  >
                    <i style={{ fontSize: "2rem" }} class="fas fa-file-pdf"></i>
                  </a>
                </div>
              )}
              {/* Comprovante de Adimplência */}
              {doc.tipo === "ADIMPLENCIA" && (
                <div className="flex horizontal">
                  <p>
                    <b>Comprovante de Adimplência:&nbsp;</b>
                  </p>
                  <a
                    href={`https://api.anapolis.go.gov.br/apiupload/cidadao/${doc.cpf}/${doc.file}`}
                    target="_blanc"
                  >
                    <i style={{ fontSize: "2rem" }} class="fas fa-file-pdf"></i>
                  </a>
                </div>
              )}
              {/* Certidão Negativa de Comissão Ética */}
              {doc.tipo === "CNCE" && (
                <div className="flex horizontal">
                  <p>
                    <b>Certidão Negativa de Comissão Ética:&nbsp;</b>
                  </p>
                  <a
                    href={`https://api.anapolis.go.gov.br/apiupload/cidadao/${doc.cpf}/${doc.file}`}
                    target="_blanc"
                  >
                    <i style={{ fontSize: "2rem" }} class="fas fa-file-pdf"></i>
                  </a>
                </div>
              )}
              {/* Certidão de Regularidade federal */}
              {doc.tipo === "CRF" && (
                <div className="flex horizontal">
                  <p>
                    <b>Certidão de Regularidade federal:&nbsp;</b>
                  </p>
                  <a
                    href={`https://api.anapolis.go.gov.br/apiupload/cidadao/${doc.cpf}/${doc.file}`}
                    target="_blanc"
                  >
                    <i style={{ fontSize: "2rem" }} class="fas fa-file-pdf"></i>
                  </a>
                </div>
              )}
              {/* Certidão de Regularidade Fazenda Estadual */}
              {doc.tipo === "CRFE" && (
                <div className="flex horizontal">
                  <p>
                    <b>Certidão de Regularidade Fazenda Estadual:&nbsp;</b>
                  </p>
                  <a
                    href={`https://api.anapolis.go.gov.br/apiupload/cidadao/${doc.cpf}/${doc.file}`}
                    target="_blanc"
                  >
                    <i style={{ fontSize: "2rem" }} class="fas fa-file-pdf"></i>
                  </a>
                </div>
              )}
              {/* Certidão de Regularidade Fazenda Municipal */}
              {doc.tipo === "CRFM" && (
                <div className="flex horizontal">
                  <p>
                    <b>Certidão de Regularidade Fazenda Municipal:&nbsp;</b>
                  </p>
                  <a
                    href={`https://api.anapolis.go.gov.br/apiupload/cidadao/${doc.cpf}/${doc.file}`}
                    target="_blanc"
                  >
                    <i style={{ fontSize: "2rem" }} class="fas fa-file-pdf"></i>
                  </a>
                </div>
              )}
              {/* Certidão negativa de débitos trabalhistas */}
              {doc.tipo === "CNDT" && (
                <div className="flex horizontal">
                  <p>
                    <b>Certidão negativa de débitos trabalhistas:&nbsp;</b>
                  </p>
                  <a
                    href={`https://api.anapolis.go.gov.br/apiupload/cidadao/${doc.cpf}/${doc.file}`}
                    target="_blanc"
                  >
                    <i style={{ fontSize: "2rem" }} class="fas fa-file-pdf"></i>
                  </a>
                </div>
              )}
              {/* Certidão negativa de débitos trabalhistas */}
              {doc.tipo === "CNDT" && (
                <div className="flex horizontal">
                  <p>
                    <b>Certidão negativa de débitos trabalhistas:&nbsp;</b>
                  </p>
                  <a
                    href={`https://api.anapolis.go.gov.br/apiupload/cidadao/${doc.cpf}/${doc.file}`}
                    target="_blanc"
                  >
                    <i style={{ fontSize: "2rem" }} class="fas fa-file-pdf"></i>
                  </a>
                </div>
              )}
            </div>
          ))}
        </div>
      </div>

      <div className="Show-Inscricao-Container-upload flex">
        <div className="flex flex-2">
          <FormControl className={classes.formControl}>
            <InputLabel htmlFor="age-native-simple">
              Selecione o tipo de documento...
            </InputLabel>
            <Select
              className={classes.select}
              native
              //   value={state.age}
              onChange={(e) => setTipoDocumento(e.target.value)}
              inputProps={{
                name: "age",
                id: "age-native-simple",
              }}
            >
              {documentsNecessary.map((item, i) => (
                <option key={i} value={item.name} disabled={item.disabled}>
                  {item.label}
                </option>
              ))}
            </Select>
          </FormControl>
        </div>

        <div className="Input-Arquivo flex vertical flex-1">
          <input
            className="inputFile"
            id="regularidade_debito_trabalhista"
            type="file"
            onChange={handleChangeFile}
            name="regularidade_debito_trabalhista"
            accept="application/pdf"
          />
          <br></br>
          {loading === false ? (
            <button onClick={uploadFile} className="upbutton">
              Salvar
            </button>
          ) : (
            <Progress />
          )}
          <hr />
        </div>
      </div>
    </div>
  )
}
