export const TOKEN_KEY = "Bearer";
export const isAuthenticated = () => localStorage.getItem(TOKEN_KEY) !== null;
export const getToken = () => {
  const token1 = localStorage.getItem('token1');
  const token2 = localStorage.getItem('token2');
  const token3 = localStorage.getItem('token3');
  if (!token1 || !token2 || !token3) return null;
  return `${token1}.${token2}.${token3}`;
}
export const getHeaders = () => {
  return {
    "headers": {
      'authorization': `Bearer ${getToken()}`
    }
  }
}
export const login = token => {
  localStorage.setItem(TOKEN_KEY, token);
};
export const logout = () => {
  localStorage.removeItem('token1');
  localStorage.removeItem('token2');
  localStorage.removeItem('token3');
  return (window.location = "/");
};