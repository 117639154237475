import React, { Component } from 'react'
import Sidebar from '../../Sidebar'
import Footer from '../../Footer'
import InputSimples from '../../../components/Inputs/Simples'
import Button from '../../../components/Button/Simples'
import { api } from '../../../services/api'
import { getHeaders } from '../../../services/auth'
import { Link } from 'react-router-dom'
import moment from 'moment'
import axios from 'axios'

export default class ShowInscricaoAdm extends Component {
    state = {
        inscricao: '',
        password: ''
    }

    async updateSenha() {
        api.put(
            `credsaude/usuarios/adm`,
            { password: this.state.password },
            getHeaders()
        )
            .then((response) => {
                alert('Senha alterada com sucesso')
            })
            .then((response) => {
                window.location.href = '/adm/inscricoes'
            })
            .catch((e) => console.log(e));
    }

    onChange = (f, v) => this.setState({ [f]: v });

    render() {
        const { password } = this.state

        return (
            <div>
                <Sidebar />
                <div className='Show-Inscricao'>
                    <div style={{ fontSize: '1.2rem', margin: '15px' }}>
                        <Link to='/adm/inscricoes'>Voltar </Link>
                    </div>
                    <div className='flex horizontal'>
                        <InputSimples
                            label='Nova senha'
                            value={password}
                            onChange={(e) => this.onChange("password", e.target.value)}
                        />
                    </div>
                    <Button
                        label={'Salvar'}
                        type={'inscricao'}
                        onClick={() => this.updateSenha()}
                    />
                </div>
                <Footer />
            </div>
        )
    }
}