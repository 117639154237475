import React, { Component } from 'react';
import 'react-bootstrap-table-next/dist/react-bootstrap-table2.min.css'
import { api } from '../../../services/api'
import Sidebar from '../../Sidebar'
import Footer from '../../Footer'
import BootstrapTable from 'react-bootstrap-table-next';
import ToolkitProvider, { Search } from 'react-bootstrap-table2-toolkit';
import paginationFactory from 'react-bootstrap-table2-paginator';

export default class Clientes extends Component {
    state = {
        inscricoes: '',
        atual: 0,
        limit: 5
    }

    async loadInscricoes() {
        await api.get("/inscricoes/adm")
            .then(response => {
                this.setState({
                    inscricoes: response.data.inscricoes
                })
            })
            .catch(err => {
                console.log(err);
            });
    };

    componentDidMount() {
        this.loadInscricoes()
    }

    onDetalhes(row) {
        window.location.href = 'inscricoes/detalhes/' + row.id
    }
    onDelete(row) {
    }

    changeNumeroAtual = (atual) => this.setState({ atual }, () => this.loadInscricoes())
    render() {
        const { inscricoes } = this.state;
        const dados = [];
        (inscricoes ? inscricoes : []).forEach((item) => {
            dados.push({
                'id': item.id,
                'nome_completo': item.nome_completo ? item.nome_completo : '',
                'cpf': item.cpf,
                'cargo': item.cargo,
                'botaoDetalhes': `/inscricoes/${item._id}`
            })
        })
        const { SearchBar } = Search;
        const columns = [{
            dataField: 'id',
            text: 'Posição',
            align: 'center',
            sort: true,
            headerStyle: { width: '8%' },
        }, {
            dataField: 'nome_completo',
            text: 'Nome'
        }, {
            dataField: 'cpf',
            text: 'CPF',
            align: 'center',
            headerStyle: { width: '15%' },
        },
        {
            dataField: 'cargo',
            text: 'Cargo',
        },
        {
            dataField: 'id',
            isDummyField: true,
            text: '',
            headerStyle: { width: '5%' },
            align: 'center',
            with: '20px',
            formatter: (cellContent, row) => {
                return (
                    <button onClick={() => this.onDetalhes(row)}><i class="fa fa-eye" aria-hidden="true"></i></button>
                )
            }
        },
            // {
            //     dataField: 'id',
            //     isDummyField: true,
            //     text: 'Deletar',
            //     headerStyle: { width: '5%' },
            //     align: 'center',
            //     formatter: (cellContent, row) => {
            //         return (
            //             <button onClick={() => this.onDelete(row)}><i class="fa fa-trash" aria-hidden="true"></i></button>
            //         )
            //     }
            // }
        ];
        return (
            <div>
                <Sidebar />
                <div className="Inscricoes">
                    <ToolkitProvider
                        keyField="id"
                        data={dados}
                        columns={columns}
                        search
                    >
                        {
                            props => (
                                <div>
                                    <h3>Inscrições:</h3>
                                    <SearchBar {...props.searchProps} />
                                    <hr />
                                    <BootstrapTable
                                        {...props.baseProps}
                                        sort={{ dataField: 'id', order: 'asc' }}
                                        pagination={paginationFactory()}
                                    />
                                </div>
                            )
                        }
                    </ToolkitProvider>
                </div>
                <Footer />
            </div>

        )
    }
}