import React, { Component } from 'react';
import 'react-bootstrap-table-next/dist/react-bootstrap-table2.min.css'
import { api } from '../../../services/api'
import Button from '../../../components/Button/Simples'
import Sidebar from '../../Sidebar'
import Footer from '../../Footer'
import BootstrapTable from 'react-bootstrap-table-next';
import ToolkitProvider, { Search } from 'react-bootstrap-table2-toolkit';
import paginationFactory from 'react-bootstrap-table2-paginator';

export default class Clientes extends Component {
    state = {
        usuario: '',
        usuarios: '',
        atual: 0,
        limit: 5
    }

    async loadUsuarios() {
        await api.get('usuarios/')
            .then(response => {
                this.setState({
                    usuario: response.data.usuario
                })
            })
            .catch(err => {
                console.log(err);
            });

        await api.get("/usuarios/adm")
            .then(response => {
                this.setState({
                    usuarios: response.data.usuario
                })
            })
            .catch(err => {
                console.log(err);
            });
    };

    componentDidMount() {
        this.loadUsuarios()
    }

    novoUsuario() {
    }
    onDetalhes(row) {
        window.location.href = 'usuarios/detalhes/' + row.id
    }
    onDelete(row) {
    }

    changeNumeroAtual = (atual) => this.setState({ atual }, () => this.loadUsuarios())
    render() {
        const { usuarios, usuario } = this.state;
        const dados = [];
        (usuarios ? usuarios : []).forEach((item) => {
            dados.push({
                'id': item.id,
                'nome': item.nome ? item.nome : '',
                'cpf': item.cpf,
                'botaoDetalhes': `/usuarios/${item._id}`
            })
        })
        const { SearchBar } = Search;
        const columns = [{
            dataField: 'nome',
            text: 'Nome'
        }, {
            dataField: 'cpf',
            text: 'CPF'
        },
        ];
        return (
            <div>
                <Sidebar />
                <div className="Inscricoes">
                    <br />
                    {usuario.permissao === 'Super-Adm' && <Button
                        type='inscricao'
                        label='Novo usuário'
                        onClick={() => window.location.href = '/adm/usuarios/create'} />}
                    <br />
                    <ToolkitProvider
                        keyField="id"
                        data={dados}
                        columns={columns}
                        search
                    >
                        {
                            props => (
                                <div>
                                    <h3>Inscrições:</h3>
                                    <SearchBar {...props.searchProps} />
                                    <hr />
                                    <BootstrapTable
                                        {...props.baseProps}
                                        pagination={paginationFactory()}
                                    />
                                </div>
                            )
                        }
                    </ToolkitProvider>
                </div>

                <Footer />
            </div>

        )
    }
}