import React from 'react';
import Switch from '@material-ui/core/Switch';

export default function Switches({ checked, onChange }) {
  const [state, setState] = React.useState({
    checkedA: true,
    checkedB: true,
  });

  return (
    <div>
      <Switch
        checked={checked}
        onChange={onChange}
        color="primary"
        name="checkedB"
        inputProps={{ 'aria-label': 'primary checkbox' }}
      />
    </div>
  );
}