import React, { Component } from 'react'
import { api } from '../../../services/api'
import InputSimples from '../../../components/Inputs/Simples'
import Button from '../../../components/Button/Simples'
import { Link } from 'react-router-dom';
import Sidebar from '../../Sidebar'
import Footer from '../../Footer'
import axios from 'axios';

export default class NovoUsuario extends Component {

    constructor(props) {
        super(props);
        this.state = {
            nome: '',
            cpf: '',
            erros: {}
        }

    }

    mCPF = (cpf) => {
        cpf = cpf.replace(/\D/g, "")
        cpf = cpf.replace(/(\d{3})(\d)/, "$1.$2")
        cpf = cpf.replace(/(\d{3})(\d)/, "$1.$2")
        cpf = cpf.replace(/(\d{3})(\d{1,2})$/, "$1-$2")
        return cpf
    }

    ValidaCPF = () => {
        var RegraValida = this.state.cpf;
        var cpf = RegraValida.trim();

        cpf = cpf.replace(/\./g, '');
        cpf = cpf.replace('-', '');
        cpf = cpf.split('');
        var v1 = 0;
        var v2 = 0;
        var aux = false;

        for (var i = 1; cpf.length > i; i++) {
            if (cpf[i - 1] != cpf[i]) {
                aux = true;
            }
        }

        if (aux == false) {
            return false;
        }

        for (var i = 0, p = 10; (cpf.length - 2) > i; i++, p--) {
            v1 += cpf[i] * p;
        }

        v1 = ((v1 * 10) % 11);

        if (v1 == 10) {
            v1 = 0;
        }

        if (v1 != cpf[9]) {
            return false;
        }

        for (var i = 0, p = 11; (cpf.length - 1) > i; i++, p--) {
            v2 += cpf[i] * p;
        }

        v2 = ((v2 * 10) % 11);

        if (v2 == 10) {
            v2 = 0;
        }

        if (v2 != cpf[10]) {
            return false;
        } else {
            return true;
        }
    }


    validate() {
        const {
            nome,
            cpf,
        } = this.state;
        const erros = {};
        var re = /\S+@\S+\.\S+/;

        if (!nome) erros.nome = "Preencha aqui com o seu nome_completo";
        if (!cpf) erros.cpf = "Preencha aqui com o seu CPF";

        if (!this.ValidaCPF()) erros.validaCpf = 'CPF inválido'

        this.setState({ erros, aviso: null });
        return (Object.keys(erros).length === 0);
    }

    //REGISTRO NO BANCO

    handleSubmit = (form, cb) => dispatch => {
        if (Object.keys(this.state.erros).length > 0) {
            alert('Preencha todos os campos')
        }
        api.post(`usuarios/registrar`, {
            nome: form.nome,
            cpf: form.cpf,
            password: '123456'
        })
            .then(response => this.setState({ inscricao: response.data.inscricoes }))
            .then(response => window.location.href = '/adm/usuarios')
            .catch(error => { console.log(error) });
    };

    onChange = (f, v) => this.setState({ [f]: v }, () => this.validate());

    render() {
        const {
            nome,
            cpf,
            erros
        } = this.state


        return (
            <div>
                <Sidebar />
                <div className='Form-Inscricao'>
                    <div style={{ fontSize: '1.2rem', margin: '15px' }}>
                        <Link to='/'>Voltar </Link>
                    </div>
                    <div className='Form-Inscricao-Container flex horizontal'>
                        <InputSimples
                            type='text'
                            label='Nome'
                            value={nome}
                            erro={erros.nome}
                            helpText={'Preencha o nome'}
                            onChange={(e) => this.onChange("nome", e.target.value)}
                        />
                        <InputSimples
                            validate={this.ValidaCPF() === false ? false : true}
                            label='CPF'
                            style={{ backgroundColor: 'red !important' }}
                            value={cpf}
                            erro={this.ValidaCPF() === false ? true : false}
                            helpText={'CPF inválido'}
                            id='RegraValida'
                            name='RegraValida'
                            onChange={(e) => this.setState({ cpf: this.mCPF(e.target.value) }, () => this.validate())}
                        />
                    </div>
                    <Button
                        type='inscricao'
                        label='Salvar'
                        onClick={this.handleSubmit(this.state)} />
                </div>
                <footer>
                    <Footer />
                </footer>

            </div >


        )
    }
}