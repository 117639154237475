import React, { Component } from 'react';
import { Link } from 'react-router-dom'
import { getToken, logout } from '../services/auth';
import { api } from '../services/api'

export default class Siderbar extends Component {
  state = {
    usuario: ''
  }

  componentDidMount() {
    const { usuario } = this.state;
    this.loadUsuario()
    //if (!authorized || !usuario || !usuario.role.includes('adm')) history.replace('/login');
  }

  async loadUsuario() {
    await api.get("/usuarios/")
      .then(response => {
        this.setState({
          usuario: response.data.usuario
        })
      })
      .catch(err => {
        console.log(err);
      });
  };
  renderMenuNoAuth() {
    return (
      <div class="col-sm-12 col-md-12" style={{ textAlign: 'center' }}>
        <div class="links">
          <a href="/Login">Acesso</a>
        </div>
      </div>
    )
  }
  renderMenuAdm() {
    return (
      <div class="col-sm-12 col-md-12 flex horizontal" style={{ textAlign: 'center' }}>
        <div class="links">
          <a href="/">Início</a>
        </div>
        <div class="links">
          <a href="/adm/inscricoes">Inscrições</a>
        </div>
        <div class="links">
          <a href="/adm/usuarios">Usuários</a>
        </div>
        <div class="links">
          <a href="/adm/usuarios/alterarSenha">Alterar Senha</a>
        </div>
        <div class="links">
          <button onClick={() => logout()}><i style={{ color: 'white', fontSize: '1.5rem' }} class="fa fa-external-link" aria-hidden="true"></i></button>
        </div>
      </div>
    )
  }
  render() {
    const { usuario } = this.state
    return (
      <div className="header_index">
        <header class="header">
          <div class="top-head">
            <div class="container">
              <div class="row">
                {(getToken()) ?
                  this.renderMenuAdm()
                  :
                  this.renderMenuNoAuth()
                }

              </div>
            </div>
          </div>
          <div class="faixa-menu"></div>
          <div id="main-nav" class="">
            <div class="text-center col-md-12">
              <nav class="navbar-pma  navbar-expand-lg navbar-light navbar navbar-expand-lg navbar-light bg-light">
                <a href="/Inicio" class="navbar-brand logoPInicial navbar-brand">
                  <img alt="logoNova" class="logoNova" src="https://cdn.anapolis.go.gov.br/img/logos/sem_fundo/azuis/saude.png" />
                </a>
              </nav>
            </div>
          </div>
          <div class="login-content pt-4 pb-4" style={{ textAlignLast: "center" }}>
            <div class="col-md-12">
              <div class="row align-content-center">
                <div class="col-sm-12">
                  <div class="msg-login">
                    <h6>Se você é funcionario, clique para fazer login.</h6>
                    <a href="/Login" class="col-md-4 pv-btn pv-btn-block pv-btn-primary button-acessar" style={{ color: "rgb(255, 255, 255)" }}>Acessar</a>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </header>
      </div>
    )
  }
}