import React, { Component } from "react"
import "./App.css"
import Sidebar from "./containers/Sidebar"
import Footer from "./containers/Footer"
import Button from "./components/Button/Simples"

export default class App extends Component {
  state = {
    dataHora: "",
  }
  componentDidMount() {
    this.dataHora()
  }

  dataHora() {
    // Obtém a data/hora atual
    var data = new Date()

    // Guarda cada pedaço em uma variável
    var dia = data.getDate() // 1-31
    var dia_sem = data.getDay() // 0-6 (zero=domingo)
    var mes = data.getMonth() // 0-11 (zero=janeiro)
    var ano2 = data.getYear() // 2 dígitos
    var ano4 = data.getFullYear() // 4 dígitos
    var hora = data.getHours() // 0-23
    var min = data.getMinutes() // 0-59
    var seg = data.getSeconds() // 0-59
    var mseg = data.getMilliseconds() // 0-999
    var tz = data.getTimezoneOffset() // em minutos

    // Formata a data e a hora (note o mês + 1)
    var str_data = dia + "/" + (mes + 1) + "/" + ano4
    var str_hora = hora + ":" + min + ":" + seg

    // Mostra o resultado
    this.setState({ dataHora: { str_data, str_hora } })
  }

  // contagemRegressiva() {
  //   var target_date = new Date("april 12, 2022").getTime()
  //   var dias, horas, minutos, segundos
  //   //var regressiva = document.getElementById("regressiva");

  //   setInterval(function () {
  //     var current_date = new Date().getTime()
  //     var segundos_f = (target_date - current_date) / 1000

  //     dias = parseInt(segundos_f / 86400)
  //     segundos_f = segundos_f % 86400

  //     horas = parseInt(segundos_f / 3600)
  //     segundos_f = segundos_f % 3600

  //     minutos = parseInt(segundos_f / 60)
  //     segundos = parseInt(segundos_f % 60)

  //     document.getElementById("dia").innerHTML = dias
  //     document.getElementById("hora").innerHTML = horas
  //     document.getElementById("minuto").innerHTML = minutos
  //     document.getElementById("segundo").innerHTML = segundos
  //   }, 1000)
  // }
  render() {
    // this.contagemRegressiva()
    console.log(this.state.dataHora)
    const { dataHora } = this.state
    return (
      <div className="Inicial">
        <Sidebar />
        <div className="Botoes flex" style={{ placeContent: "center" }}>
          {/* <Button
            label="Nova inscrição"
            type="nova-inscricao"
            onClick={() => (window.location.href = "/inscricao")}
          /> */}
          <Button
            label="Consultar inscrição"
            type="consulta"
            onClick={() => (window.location.href = "/protocolo")}
          />
        </div>

        {/* <div class="contagem">
          <span>Faltam</span>
          <table>
            <tr>
              <td>
                <div class="numero" id="dia"></div>
              </td>
              <td>
                <div class="numero" id="hora"></div>
              </td>
              <td>
                <div class="numero" id="minuto"></div>
              </td>
              <td>
                <div class="numero" id="segundo"></div>
              </td>
            </tr>
            <tr style={{ height: "20px" }}>
              <td>
                <p class="legenda">Dias</p>
              </td>
              <td>
                <p class="legenda">Horas</p>
              </td>
              <td>
                <p class="legenda">Min</p>
              </td>
              <td>
                <p class="legenda">Seg</p>
              </td>
            </tr>
          </table>
          <span>Para o fim das inscrições</span>
        </div> */}

        <div className="Links-Uteis">
          <hr />
          <p>Links úteis</p>

          <a
            href="https://servicos.receita.fazenda.gov.br/servicos/cpf/consultasituacao/consultapublica.asp"
            target="_blanc"
          >
            • Comprovante de Regularidade de situação cadastral do CPF, emitido
            no site da Receita Federal
          </a>
          <br />
          <br />
          <a
            href="http://servicos.receita.fazenda.gov.br/Servicos/certidao/CNDConjuntaInter/InformaNICertidao.asp?tipo=2"
            target="_blanc"
          >
            • Certidão negativa de Débitos Relativos a Créditos Tributários
            Federais e à Dívida Ativa da União
          </a>
          <br />
          <br />
          <a
            href="https://www.sefaz.go.gov.br/Certidao/Emissao"
            target="_blanc"
          >
            • Certidão negativa De Débito Inscrito em Divida Ativa
          </a>
          <br />
          <br />
          <a
            href="https://portaldocidadao.anapolis.go.gov.br/processos/execute.action"
            target="_blanc"
          >
            • Certidão de Regularidade Fiscal do Contribuinte – de competência
            da circunscrição do interessado
          </a>
          <br />
          <br />
          <a href="https://www.tst.jus.br/web/guest/certidao" target="_blanc">
            • Certidão negativa de débitos Trabalhistas “CPF”
          </a>
          <br />
          <br />
          <hr />
        </div>

        <div className="Footer">
          <Footer />
        </div>
      </div>
    )
  }
}
