import React, { Component } from 'react'
import { api } from '../../services/api'
import Sidebar from '../Sidebar'
import Footer from '../Footer'
import Voltar from '../../components/Links/Voltar'
import Button from '../../components/Button/Simples'
import InputSimples from '../../components/Inputs/Simples'
import ShowInscricao from './showInscricao'

export default class BuscarInscricao extends Component {

    constructor(props) {
        super(props);
        this.state = {
            protocolo: '',
            inscricao: ''
        }
        this.buscarInscricao = this.buscarInscricao.bind(this)
    }

    async buscarInscricao() {
        const { protocolo } = this.state
        await api.get('/inscricoes/search/' + protocolo)
            .then(response => {
                this.setState({ inscricao: response.data.inscricoes })
            })
            .catch(error => { console.log(error) });
    }

    onChange = (f, v) => this.setState({ [f]: v });

    render() {
        const { protocolo, inscricao } = this.state
        return (
            <div>
                <Sidebar />

                <div className='Input-Protocolo'>
                    <Voltar path='/' />
                    <br />
                    <br />
                    <InputSimples
                        type='text'
                        label='Protocolo'
                        value={protocolo}
                        onChange={(e) => this.onChange("protocolo", e.target.value)}
                    />
                    <br />
                    <Button
                        label='Buscar inscrição'
                        type='nova-inscricao'
                        onClick={this.buscarInscricao} />
                </div>
                {inscricao &&
                    <ShowInscricao inscricao={inscricao} />}
                <Footer />
            </div >


        )
    }
}