import React, { Component } from "react"
import axios from "axios"
import Sidebar from "../../Sidebar"
import Footer from "../../Footer"
import { api, apiURL } from "../../../services/api"
import { Link } from "react-router-dom"
import moment from "moment"
import InterruptorSimples from "../../../components/Button/InterruptorSimples"

export default class ShowInscricaoAdm extends Component {
  state = {
    inscricao: "",
    situacao: "",
    downloadDocumentacao: false,
    docsEnviados: null,
  }

  async componentDidMount() {
    this.loadInscricao()
  }

  async loadInscricao() {
    const id = window.location.pathname.split("/")[4]
    await api
      .get("/inscricoes/adm/" + id)
      .then((response) => {
        this.setState({
          inscricao: response.data.inscricoes,
        })
      })
      .catch((err) => {
        console.log(err)
      })

    var cpf2 = this.state.inscricao.cpf
    cpf2 = cpf2.replace(".", "")
    cpf2 = cpf2.replace(".", "")
    cpf2 = cpf2.replace("-", "")
    await axios
      .get(`https://api.anapolis.go.gov.br/apiupload/upload?cpf=${cpf2}`)
      .then((docs) => {
        this.setState({
          docsEnviados: docs.data.result,
        })
      })
      .catch((e) => console.log(e))
  }

  onChange = (f, v) => this.setState({ [f]: v })

  render() {
    const id = window.location.pathname.split("/")[4]
    const docsEnviados = this.state.docsEnviados
    const handleSubmit = (form, cb) => (dispatch) => {
      api
        .put(`inscricoes/adm/` + id, {
          situacao: form.situacao,
          obs: this.state.obs,
        })
        .then((response) => alert("Alterado com sucesso"))
        .then((response) => window.location.reload())
        .catch((error) => {
          console.log(error)
        })
    }
    const { inscricao } = this.state
    var cpf2 = inscricao.cpf ? inscricao.cpf : ""
    cpf2 = cpf2.replace(".", "")
    cpf2 = cpf2.replace(".", "")
    cpf2 = cpf2.replace("-", "")

    const handleChange = async () => {
      this.setState({ downloadDocumentacao: !this.state.downloadDocumentacao })
      await api.get("/download/" + cpf2)
    }
    return (
      <div>
        <Sidebar />
        <div className="Show-Inscricao">
          <div style={{ fontSize: "1.2rem", margin: "15px" }}>
            <Link to="/adm/inscricoes">Voltar </Link>
          </div>
          <div
            className="Show-Inscricao-Container flex"
            style={{
              marginTop: "50px",
              background: "#e8e6e6",
              width: "max-content",
              padding: "30px",
              borderRadius: "20px",
            }}
          >
            <div style={{ marginRight: "100px" }} className="flex vertical">
              <div className="flex horizontal">
                <p>
                  <b>CPF:&nbsp;</b>
                </p>
                <p>{inscricao.cpf}</p>
              </div>
              <div className="flex horizontal">
                <p>
                  <b>RG:&nbsp;</b>
                </p>
                <p>{inscricao.rg}</p>
              </div>
              <div className="flex horizontal">
                <p>
                  <b>Data de nascimento:&nbsp;</b>
                </p>
                <p>{inscricao.data_nascimento}</p>
              </div>
              <div className="flex horizontal">
                <p>
                  <b>Nome da mãe:&nbsp;</b>
                </p>
                <p>{inscricao.nome_mae}</p>
              </div>
              <div className="flex horizontal">
                <p>
                  <b>CEP:&nbsp;</b>
                </p>
                <p>{inscricao.cep}</p>
              </div>
              <div className="flex horizontal">
                <p>
                  <b>Endereço:&nbsp;</b>
                </p>
                <p>
                  {inscricao.logradouro}, {inscricao.numero},{" "}
                  {inscricao.complemento}, {inscricao.bairro},{" "}
                  {inscricao.cidade} - {inscricao.uf}
                </p>
              </div>
              <div className="flex horizontal">
                <p>
                  <b>E-mail:&nbsp;</b>
                </p>
                <p>{inscricao.email}</p>
              </div>
              <div className="flex horizontal">
                <p>
                  <b>Telefone:&nbsp;</b>
                </p>
                <p>{inscricao.telefone}</p>
              </div>
              <div className="flex horizontal">
                <p>
                  <b>Cargo:&nbsp;</b>
                </p>
                <p>{inscricao.cargo}</p>
              </div>
              <div className="flex horizontal">
                <p>
                  <b>Protocolo:&nbsp;</b>
                </p>
                <p>{inscricao.protocolo}</p>
              </div>
            </div>
            <div className="Documentos flex vertical">
              {(docsEnviados ?? []).map((doc) => (
                <div>
                  {/* CURRICULO */}
                  {doc.tipo === "CURRICULO" && (
                    <div className="flex horizontal">
                      <p>
                        <b>Curriculo:&nbsp;</b>
                      </p>
                      <a
                        href={`https://api.anapolis.go.gov.br/apiupload/cidadao/${doc.cpf}/${doc.file}`}
                        target="_blanc"
                      >
                        <i
                          style={{ fontSize: "2rem" }}
                          class="fas fa-file-pdf"
                        ></i>
                      </a>
                    </div>
                  )}
                  {/* DIPLOMA */}
                  {doc.tipo === "DIPLOMA" && (
                    <div className="flex horizontal">
                      <p>
                        <b>Diploma:&nbsp;</b>
                      </p>
                      <a
                        href={`https://api.anapolis.go.gov.br/apiupload/cidadao/${doc.cpf}/${doc.file}`}
                        target="_blanc"
                      >
                        <i
                          style={{ fontSize: "2rem" }}
                          class="fas fa-file-pdf"
                        ></i>
                      </a>
                    </div>
                  )}
                  {/* CTPS */}
                  {doc.tipo === "CTPS" && (
                    <div className="flex horizontal">
                      <p>
                        <b>CTPS:&nbsp;</b>
                      </p>
                      <a
                        href={`https://api.anapolis.go.gov.br/apiupload/cidadao/${doc.cpf}/${doc.file}`}
                        target="_blanc"
                      >
                        <i
                          style={{ fontSize: "2rem" }}
                          class="fas fa-file-pdf"
                        ></i>
                      </a>
                    </div>
                  )}
                  {/* Identidade ou CNH */}
                  {doc.tipo === "IDCNH" && (
                    <div className="flex horizontal">
                      <p>
                        <b>Identidade ou CNH:&nbsp;</b>
                      </p>
                      <a
                        href={`https://api.anapolis.go.gov.br/apiupload/cidadao/${doc.cpf}/${doc.file}`}
                        target="_blanc"
                      >
                        <i
                          style={{ fontSize: "2rem" }}
                          class="fas fa-file-pdf"
                        ></i>
                      </a>
                    </div>
                  )}
                  {/* Documento de CPF */}
                  {doc.tipo === "SITCPF" && (
                    <div className="flex horizontal">
                      <p>
                        <b>Documento de CPF:&nbsp;</b>
                      </p>
                      <a
                        href={`https://api.anapolis.go.gov.br/apiupload/cidadao/${doc.cpf}/${doc.file}`}
                        target="_blanc"
                      >
                        <i
                          style={{ fontSize: "2rem" }}
                          class="fas fa-file-pdf"
                        ></i>
                      </a>
                    </div>
                  )}
                  {/* Comprovante de residência */}
                  {doc.tipo === "COMPRESIDENCIA" && (
                    <div className="flex horizontal">
                      <p>
                        <b>Comprovante de residência:&nbsp;</b>
                      </p>
                      <a
                        href={`https://api.anapolis.go.gov.br/apiupload/cidadao/${doc.cpf}/${doc.file}`}
                        target="_blanc"
                      >
                        <i
                          style={{ fontSize: "2rem" }}
                          class="fas fa-file-pdf"
                        ></i>
                      </a>
                    </div>
                  )}
                  {/* AnexoI */}
                  {doc.tipo === "ANEXOI" && (
                    <div className="flex horizontal">
                      <p>
                        <b>AnexoI:&nbsp;</b>
                      </p>
                      <a
                        href={`https://api.anapolis.go.gov.br/apiupload/cidadao/${doc.cpf}/${doc.file}`}
                        target="_blanc"
                      >
                        <i
                          style={{ fontSize: "2rem" }}
                          class="fas fa-file-pdf"
                        ></i>
                      </a>
                    </div>
                  )}
                  {/* AnexoII */}
                  {doc.tipo === "ANEXOII" && (
                    <div className="flex horizontal">
                      <p>
                        <b>AnexoII:&nbsp;</b>
                      </p>
                      <a
                        href={`https://api.anapolis.go.gov.br/apiupload/cidadao/${doc.cpf}/${doc.file}`}
                        target="_blanc"
                      >
                        <i
                          style={{ fontSize: "2rem" }}
                          class="fas fa-file-pdf"
                        ></i>
                      </a>
                    </div>
                  )}
                  {/* Registro Conselho de Classe */}
                  {doc.tipo === "RCC" && (
                    <div className="flex horizontal">
                      <p>
                        <b>Registro Conselho de Classe:&nbsp;</b>
                      </p>
                      <a
                        href={`https://api.anapolis.go.gov.br/apiupload/cidadao/${doc.cpf}/${doc.file}`}
                        target="_blanc"
                      >
                        <i
                          style={{ fontSize: "2rem" }}
                          class="fas fa-file-pdf"
                        ></i>
                      </a>
                    </div>
                  )}
                  {/* Comprovante de Adimplência */}
                  {doc.tipo === "ADIMPLENCIA" && (
                    <div className="flex horizontal">
                      <p>
                        <b>Comprovante de Adimplência:&nbsp;</b>
                      </p>
                      <a
                        href={`https://api.anapolis.go.gov.br/apiupload/cidadao/${doc.cpf}/${doc.file}`}
                        target="_blanc"
                      >
                        <i
                          style={{ fontSize: "2rem" }}
                          class="fas fa-file-pdf"
                        ></i>
                      </a>
                    </div>
                  )}
                  {/* Certidão Negativa de Comissão Ética */}
                  {doc.tipo === "CNCE" && (
                    <div className="flex horizontal">
                      <p>
                        <b>Certidão Negativa de Comissão Ética:&nbsp;</b>
                      </p>
                      <a
                        href={`https://api.anapolis.go.gov.br/apiupload/cidadao/${doc.cpf}/${doc.file}`}
                        target="_blanc"
                      >
                        <i
                          style={{ fontSize: "2rem" }}
                          class="fas fa-file-pdf"
                        ></i>
                      </a>
                    </div>
                  )}
                  {/* Certidão de Regularidade federal */}
                  {doc.tipo === "CRF" && (
                    <div className="flex horizontal">
                      <p>
                        <b>Certidão de Regularidade federal:&nbsp;</b>
                      </p>
                      <a
                        href={`https://api.anapolis.go.gov.br/apiupload/cidadao/${doc.cpf}/${doc.file}`}
                        target="_blanc"
                      >
                        <i
                          style={{ fontSize: "2rem" }}
                          class="fas fa-file-pdf"
                        ></i>
                      </a>
                    </div>
                  )}
                  {/* Certidão de Regularidade Fazenda Estadual */}
                  {doc.tipo === "CRFE" && (
                    <div className="flex horizontal">
                      <p>
                        <b>Certidão de Regularidade Fazenda Estadual:&nbsp;</b>
                      </p>
                      <a
                        href={`https://api.anapolis.go.gov.br/apiupload/cidadao/${doc.cpf}/${doc.file}`}
                        target="_blanc"
                      >
                        <i
                          style={{ fontSize: "2rem" }}
                          class="fas fa-file-pdf"
                        ></i>
                      </a>
                    </div>
                  )}
                  {/* Certidão de Regularidade Fazenda Municipal */}
                  {doc.tipo === "CRFM" && (
                    <div className="flex horizontal">
                      <p>
                        <b>Certidão de Regularidade Fazenda Municipal:&nbsp;</b>
                      </p>
                      <a
                        href={`https://api.anapolis.go.gov.br/apiupload/cidadao/${doc.cpf}/${doc.file}`}
                        target="_blanc"
                      >
                        <i
                          style={{ fontSize: "2rem" }}
                          class="fas fa-file-pdf"
                        ></i>
                      </a>
                    </div>
                  )}
                  {/* Certidão negativa de débitos trabalhistas */}
                  {doc.tipo === "CNDT" && (
                    <div className="flex horizontal">
                      <p>
                        <b>Certidão negativa de débitos trabalhistas:&nbsp;</b>
                      </p>
                      <a
                        href={`https://api.anapolis.go.gov.br/apiupload/cidadao/${doc.cpf}/${doc.file}`}
                        target="_blanc"
                      >
                        <i
                          style={{ fontSize: "2rem" }}
                          class="fas fa-file-pdf"
                        ></i>
                      </a>
                    </div>
                  )}
                  {/* Certidão negativa de débitos trabalhistas */}
                  {doc.tipo === "CNDT" && (
                    <div className="flex horizontal">
                      <p>
                        <b>Certidão negativa de débitos trabalhistas:&nbsp;</b>
                      </p>
                      <a
                        href={`https://api.anapolis.go.gov.br/apiupload/cidadao/${doc.cpf}/${doc.file}`}
                        target="_blanc"
                      >
                        <i
                          style={{ fontSize: "2rem" }}
                          class="fas fa-file-pdf"
                        ></i>
                      </a>
                    </div>
                  )}
                </div>
              ))}
            </div>
          </div>
          <InterruptorSimples
            checked={this.state.downloadDocumentacao}
            onChange={handleChange}
          />
          {this.state.downloadDocumentacao === true && (
            <button
              className="Button-Download"
              onClick={() =>
                (window.location.href = `${apiURL}filesInscricoes/${cpf2}/${cpf2}.zip`)
              }
            >
              Download da documentação{" "}
              <i class="fa fa-download" aria-hidden="true"></i>
            </button>
          )}
          <div className="Show-Inscricao-Situacao">
            <br />
            <span>Situação</span>
            <br />
            <select
              onChange={(e) => this.onChange("situacao", e.target.value)}
              name="situacao"
              id="situacao"
            >
              <option value={this.state.situacao}>
                {this.state.inscricao.situacao}
              </option>
              <option value="Em análise">Em análise</option>
              <option value="Habilitado">Habilitado</option>
              <option value="Inabilitado">Inabilitado</option>
            </select>
            <br />
            <br />
            {(this.state.situacao === "Inabilitado" ||
              inscricao.situacao === "Inabilitado") && (
              <textarea
                onChange={(e) => this.onChange("obs", e.target.value)}
                placeholder="Observações"
              >
                {inscricao.obs}
              </textarea>
            )}
            <br />
            <br />

            <button onClick={handleSubmit(this.state)}>Salvar</button>
          </div>
        </div>
        <Footer />
      </div>
    )
  }
}
