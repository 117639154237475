import React, { Component } from 'react';
export default class Footer extends Component {
    render() {
        return (

            <footer class="footer-pma footer2 col-md-12 mt-1" id="footer" style={{ paddingRight: "0px", paddingLeft: "0px" }}>
                <div class="copy">
                    <div class="faixa-footer"></div>
                    <div class="text-center">
                        <div class="row">
                            <div class="col-sm-12 col-md-4 border-right-col text-left">
                                <img class="img-responsive" alt="logosocial" src="https://cdn.anapolis.go.gov.br/img/logos/sem_fundo/brancas/saude.png" style={{ margin: "15px", width: "80%" }} />
                            </div>
                            <div class="col-md-4">
                                <p class="text-center col-md-12" style={{ fontStyle: "oblique", fontSize: "13px" }}>© Copyright 2021 | #IMUNIZAANÁPOLIS - Todos os Direitos Reservados<br />
                                    <span style={{ fontStyle: "oblique", fontSize: "13px" }}>Secretaria de Comunicação, Eventos e Modernização</span><br />
                                    <span style={{ fontStyle: "oblique", fontSize: "13px" }}>Diretoria de Sistemas</span>
                                </p>
                            </div>
                            <div class="col-sm-12 col-md-4 text-right" style={{ marginLeft: "-10px", color: "white" }}>
                                <b>Endereço</b>: R. Prof. Roberto Mange, 152<br />Centro - Anápolis - GO<br />Fone: (62)3902-2560
                                    </div>
                        </div>
                    </div>
                </div>
            </footer>
        );
    }
}
