import React, { Component } from "react"
import moment from "moment"
import { api } from "../../services/api"
import InputSimples from "../../components/Inputs/Simples"
import SelectSimples from "../../components/Inputs/Select"
import DateSimples from "../../components/Inputs/Date"
import { Link } from "react-router-dom"
import Button from "../../components/Button/Simples"
import AlertSimples from "../../components/Alert/Simples"
import Sidebar from "../Sidebar"
import Footer from "../Footer"
import ModalSimples from "../../components/Modal/Simples"
import { MdCheckCircle, MdError } from "react-icons/md"
import $ from "jquery"

export default class NovaInscricao extends Component {
  constructor(props) {
    super(props)
    this.state = {
      inscricaoDB: "",
      nome_completo: "",
      cpf: "",
      rg: "",
      data_nascimento: "",
      nome_mae: "",
      cep: "",
      logradouro: "",
      numero: "",
      complemento: "",
      bairro: "",
      cidade: "",
      uf: "",
      email: "",
      telefone: "",
      cargo: "",
      erros: {},
      aviso: "",
      inscricao: "",
      ok: false,
      progress: 0,
      file: {
        doc: {},
        categoria: "",
      },
      open: false,
      curriculo: false,
      identidade_cnh: false,
      cpf_doc: false,
      experiencia_saude_mental: false,
      anexo_i: false,
      anexo_ii: false,
      comprovante_residencia: false,
      ctps: false,
      diploma: false,
      registro: false,
      rcc: false,
      adimplencia: false,
      nada_consta: false,
      regularidade_federal: false,
      regularidade_fazenda_estadual: false,
      regularidade_fazenda_municipal: false,
      regularidade_debito_trabalhista: false,
      finalizado: false,
      nome_file: [],
    }
    this.uploadFile = this.uploadFile.bind(this)
  }
  //UPLOAD ARQUIVO NO BANCO
  async uploadFile() {
    const { file, inscricao, cpf, inscricaoDB } = this.state
    const categoria = file.categoria
    const formData = new FormData()
    formData.append("file", file.doc)

    const inscricaoId = inscricaoDB ? inscricaoDB.id : inscricao.id
    const myHeaders = {
      "Content-Type": "multipart/form-data",
    }
    var cpf2 = cpf
    cpf2 = cpf2.replace(".", "")
    cpf2 = cpf2.replace(".", "")
    cpf2 = cpf2.replace("-", "")

    await api
      .put(
        `/inscricoes/images/${inscricaoId}?categoria=${categoria}&cpf=${cpf2}`,
        formData,
        {
          headers: myHeaders,
        }
      )
      .then((res) => {
        this.setState({ [categoria]: true })
      })
      .catch((err) => console.log(err))
  }

  componentDidMount() {
    const teste = (data) => {
      this.setState({
        bairro: data.bairro,
        logradouro: data.logradouro,
        cidade: data.localidade,
        uf: data.uf,
      })
    }

    //Quando o campo cep perde o foco.
    $("#cep").blur(function () {
      //Nova variável "cep" somente com dígitos.
      var cep = $(this).val().replace(/\D/g, "")

      //Verifica se campo cep possui valor informado.
      if (cep != "") {
        //Expressão regular para validar o CEP.
        var validacep = /^[0-9]{8}$/

        //Valida o formato do CEP.
        if (validacep.test(cep)) {
          //Preenche os campos com "..." enquanto consulta webservice.
          $("#rua").val("...")
          $("#bairro").val("...")
          $("#cidade").val("...")
          $("#uf").val("...")

          //Consulta o webservice viacep.com.br/
          $.getJSON(
            "https://viacep.com.br/ws/" + cep + "/json/?callback=?",
            function (dados) {
              teste(dados)
              if (!("erro" in dados)) {
                //Atualiza os campos com os valores da consulta.
                if (dados.bairro == "") {
                  $("#bairro").val("SEM BAIRRO")
                } else {
                  $("#bairro").val(dados.bairro)
                }
                if (dados.logradouro == "") {
                  $("#rua").val("")
                  $("#rua").prop("readonly", false)
                  $("#rua").focus()
                } else {
                  $("#rua").val(dados.logradouro)
                  $("#rua").prop("readonly", true)
                  $("#numero").focus()
                }
                $("#cidade").val(dados.localidade)
                $("#uf").val(dados.uf)
                $("#bairro").prop("readonly", true)
                $("#cidade").prop("readonly", true)
                $("#uf").prop("readonly", true)
              } //end if.
              else {
                if (cep === "75000000") {
                  $("#rua").val("")
                  $("#bairro").val("")
                  $("#cidade").val("Anápolis")
                  $("#uf").val("GO")
                  $("#cidade").prop("readonly", true)
                  $("#uf").prop("readonly", true)
                }
              }
            }
          )
        }
      }
    })
  }

  mCPF = (cpf) => {
    cpf = cpf.replace(/\D/g, "")
    cpf = cpf.replace(/(\d{3})(\d)/, "$1.$2")
    cpf = cpf.replace(/(\d{3})(\d)/, "$1.$2")
    cpf = cpf.replace(/(\d{3})(\d{1,2})$/, "$1-$2")
    return cpf
  }

  ValidaCPF = () => {
    var RegraValida = this.state.cpf
    var cpf = RegraValida.trim()

    cpf = cpf.replace(/\./g, "")
    cpf = cpf.replace("-", "")
    cpf = cpf.split("")
    var v1 = 0
    var v2 = 0
    var aux = false

    for (var i = 1; cpf.length > i; i++) {
      if (cpf[i - 1] != cpf[i]) {
        aux = true
      }
    }

    if (aux == false) {
      return false
    }

    for (var i = 0, p = 10; cpf.length - 2 > i; i++, p--) {
      v1 += cpf[i] * p
    }

    v1 = (v1 * 10) % 11

    if (v1 == 10) {
      v1 = 0
    }

    if (v1 != cpf[9]) {
      return false
    }

    for (var i = 0, p = 11; cpf.length - 1 > i; i++, p--) {
      v2 += cpf[i] * p
    }

    v2 = (v2 * 10) % 11

    if (v2 == 10) {
      v2 = 0
    }

    if (v2 != cpf[10]) {
      return false
    } else {
      return true
    }
  }

  mudarStateUpload() {
    const { inscricaoDB } = this.state
    if (inscricaoDB) {
      inscricaoDB.curriculo && this.setState({ curriculo: true })
      inscricaoDB.ctps && this.setState({ ctps: true })
      inscricaoDB.identidade_cnh && this.setState({ identidade_cnh: true })
      inscricaoDB.cpf_doc && this.setState({ cpf_doc: true })
      //
      inscricaoDB.experiencia_saude_mental &&
        this.setState({ experiencia_saude_mental: true })
      inscricaoDB.anexo_i && this.setState({ anexo_i: true })
      inscricaoDB.anexo_ii && this.setState({ anexo_ii: true })
      //
      inscricaoDB.comprovante_residencia &&
        this.setState({ comprovante_residencia: true })
      inscricaoDB.diploma && this.setState({ diploma: true })
      inscricaoDB.regularidade_federal &&
        this.setState({ regularidade_federal: true })
      inscricaoDB.regularidade_fazenda_estadual &&
        this.setState({ regularidade_fazenda_estadual: true })
      inscricaoDB.regularidade_fazenda_municipal &&
        this.setState({ regularidade_fazenda_municipal: true })
      inscricaoDB.regularidade_debito_trabalhista &&
        this.setState({ regularidade_debito_trabalhista: true })
      inscricaoDB.rcc && this.setState({ rcc: true })
      inscricaoDB.adimplencia && this.setState({ adimplencia: true })
      inscricaoDB.nada_consta && this.setState({ nada_consta: true })
    }
  }

  async buscaCPF() {
    await api
      .get("/inscricoes/search/cpf/" + this.state.cpf)
      .then((response) => {
        this.setState({ inscricaoDB: response.data.inscricoes })
      })
      .catch((error) => {
        console.log(error)
      })
    this.state.inscricaoDB === null
      ? this.buscaHabilitado()
      : this.mudarStateUpload()
  }

  async buscaHabilitado() {
    await api
      .get("/inscricoes/search/habilitado/" + this.state.cpf)
      .then((response) => {
        this.setState({ inscricaoDB: response.data.habilitado })
      })
      .catch((error) => {
        console.log(error)
      })
    this.mudarStateUpload()
  }

  validate() {
    const {
      nome_completo,
      cpf,
      rg,
      data_nascimento,
      nome_mae,
      cep,
      numero,
      complemento,
      email,
      telefone,
      cargo,
    } = this.state
    const erros = {}
    var re = /\S+@\S+\.\S+/

    if (!nome_completo)
      erros.nome_completo = "Preencha aqui com o seu nome_completo"
    if (!cpf) erros.cpf = "Preencha aqui com o seu CPF"
    if (!rg) erros.rg = "Preencha aqui com o seu RG"
    if (!data_nascimento)
      erros.data_nascimento = "Preencha aqui com a sua data de nascimento"
    if (!nome_mae) erros.nome_mae = "Preencha aqui com o nome da sua mãe"
    if (!cep) erros.cep = "Preencha aqui com o seu cep"
    if (!numero) erros.numero = "Preencha aqui com o seu numero"
    if (!re.test(email))
      erros.email = "Preencha aqui com o seu E-mail corretamente"
    if (!telefone) erros.telefone = "Preencha aqui com o seu telefone"
    if (!cargo) erros.cargo = "Preencha aqui com o seu cargo"
    if (!this.ValidaCPF()) erros.validaCpf = "CPF inválido"
    if (this.ValidaCPF()) this.buscaCPF()

    this.setState({ erros, aviso: null })
    return Object.keys(erros).length === 0
  }

  onChange = (f, v) => this.setState({ [f]: v }, () => this.validate())

  render() {
    const {
      nome_completo,
      cpf,
      rg,
      data_nascimento,
      nome_mae,
      cep,
      logradouro,
      numero,
      complemento,
      email,
      telefone,
      cargo,
      erros,
      protocolo,
      inscricao,
      inscricaoDB,
    } = this.state
    const cargos = [
      { nome: "Arteterapeuta", value: "Arteterapeuta" },
      { nome: "Fonoaudiólogo", value: "Fonoaudiólogo" },
      { nome: "Médico Gastropediatra", value: "Médico Gastropediatra" },
      { nome: "Médico Cardiologista", value: "Médico Cardiologista" },
      { nome: "Médico Dermatologista", value: "Médico Dermatologista" },
      { nome: "Médico Urologista", value: "Médico Urologista" },
      { nome: "Médico Hematologista", value: "Médico Hematologista" },
      { nome: "Médico Clinico", value: "Médico Clinico" },
      {
        nome: "Médico Clinico com Experiência em Saúde Mental",
        value: "Médico Clinico com Experiência em Saúde Mental",
      },
      { nome: "Médico Endocrinologista	", value: "Médico Endocrinologista	" },
      { nome: "Médico Fisiatra", value: "Médico Fisiatra" },
      {
        nome: "Médico Gastroenterologista",
        value: "Médico Gastroenterologista",
      },
      { nome: "Médico Cirurgião Geral	", value: "Médico Cirurgião Geral	" },
      { nome: "Médico Geriatra", value: "Médico Geriatra" },
      { nome: "Médico Geneticista", value: "Médico Geneticista" },
      { nome: "Médico Infectologista", value: "Médico Infectologista" },
      { nome: "Médico Neuropediatra", value: "Médico Neuropediatra" },
      { nome: "Médico Neurologista", value: "Médico Neurologista" },
      {
        nome: "Médico Otorrinolaringologista",
        value: "Médico Otorrinolaringologista",
      },
      { nome: "Médico Pneumologista", value: "Médico Pneumologista" },
      { nome: "Médico Psiquiatra", value: "Médico Psiquiatra" },
      { nome: "Médico Reumatologista", value: "Médico Reumatologista" },
      { nome: "Médico Pediatra", value: "Médico Pediatra" },
      {
        nome: "Médico Ortopedista Traumatologista",
        value: "Médico Ortopedista Traumatologista",
      },
      {
        nome: "Médico Radiologista/Ultrassonografista",
        value: "Médico Radiologista/Ultrassonografista",
      },
      { nome: "Médico Nefrologista", value: "Médico Nefrologista" },
      { nome: "Médico NeuroCirurgião", value: "Médico NeuroCirurgião" },
      {
        nome: "Médico Alergista/Imunologista",
        value: "Médico Alergista/Imunologista",
      },
      { nome: "Médico Oftalmologista", value: "Médico Oftalmologista" },
      {
        nome: "Médico Ginecologista Obstetra",
        value: "Médico Ginecologista Obstetra",
      },
      { nome: "Musicoterapeuta", value: "Musicoterapeuta" },
      { nome: "Terapeuta Ocupacional", value: "Terapeuta Ocupacional" },
    ]

    //REGISTRO NO BANCO

    const handleSubmit = (form, cb) => (dispatch) => {
      if (Object.keys(this.state.erros).length > 0) {
        alert("Preencha todos os campos")
      }
      const numero_aleatorio = Math.floor(Math.random() * 65536 * Math.random())
      api
        .post(`inscricoes`, {
          nome_completo: form.nome_completo,
          cpf: form.cpf,
          rg: form.rg,
          data_nascimento: form.data_nascimento,
          nome_mae: form.nome_mae,
          cep: form.cep,
          logradouro: form.logradouro,
          numero: form.numero,
          complemento: form.complemento,
          bairro: form.bairro,
          cidade: form.cidade,
          uf: form.uf,
          email: form.email,
          telefone: form.telefone,
          cargo: form.cargo,
          protocolo: "CS" + numero_aleatorio + "2021",
        })
        .then((response) =>
          this.setState({ inscricao: response.data.inscricoes })
        )
        .then((response) => this.setState({ finalizado: true }))
        .catch((error) => {
          console.log(error)
        })
    }

    //CARREGAR ARQUIVO
    const handleChangeFile = (e) => {
      const { nome_file } = this.state
      this.setState({ progress: 0 })
      var file = e.target.files[0] // accesing file
      if (file && file.type === "application/pdf") {
        this.setState({ file: { doc: file, categoria: e.target.id } }) // storing file
        nome_file.push(([e.target.name] = file.name))
      } else {
        alert("Extensão não suportada, selecione outro arquivo")
        this.setState({ file: { doc: "", categoria: "" } }) // storing file
      }
      this.mudarStateUpload()
    }

    return (
      <div>
        <Sidebar />
        <AlertSimples open={this.state.open} />
        <div className="Form-Inscricao">
          <div style={{ fontSize: "1.2rem", margin: "15px" }}>
            <Link to="/">Voltar </Link>
          </div>
          <div className="Form-Inscricao-Container flex horizontal">
            <InputSimples
              type="text"
              label="Nome completo"
              value={inscricaoDB ? inscricaoDB.nome_completo : nome_completo}
              erro={
                inscricaoDB ? (erros.nome_completo = "") : erros.nome_completo
              }
              helpText={"Preencha seu nome completo"}
              onChange={(e) => this.onChange("nome_completo", e.target.value)}
            />
            <InputSimples
              validate={this.ValidaCPF() === false ? false : true}
              label="CPF"
              style={{ backgroundColor: "red !important" }}
              value={cpf}
              erro={this.ValidaCPF() === false ? true : false}
              helpText={"CPF inválido"}
              id="RegraValida"
              name="RegraValida"
              onChange={(e) =>
                this.setState({ cpf: this.mCPF(e.target.value) }, () =>
                  this.validate()
                )
              }
            />
            <InputSimples
              label="RG"
              erro={inscricaoDB ? (erros.rg = "") : erros.rg}
              value={inscricaoDB ? inscricaoDB.rg : rg}
              helpText={"Preencha seu RG"}
              onChange={(e) => this.onChange("rg", e.target.value)}
            />
          </div>

          <div className="Form-Inscricao-Container flex horizontal">
            <DateSimples
              label="Data de nascimento"
              type="date"
              erro={
                inscricaoDB
                  ? (erros.data_nascimento = "")
                  : erros.data_nascimento
              }
              value={
                inscricaoDB
                  ? moment(inscricaoDB.data_nascimento).format("YYYY-MM-DD")
                  : data_nascimento
              }
              helpText={"Marque sua data de nascimento"}
              onChange={(e) => this.onChange("data_nascimento", e.target.value)}
            />
            <InputSimples
              label="Nome da mãe"
              erro={inscricaoDB ? (erros.nome_mae = "") : erros.nome_mae}
              value={inscricaoDB ? inscricaoDB.nome_mae : nome_mae}
              helpText={"Preencha o nome da sua mãe"}
              onChange={(e) => this.onChange("nome_mae", e.target.value)}
            />
            <InputSimples
              label="CEP"
              value={inscricaoDB ? inscricaoDB.cep : cep}
              id="cep"
              name="cep"
              helpText={"Preencha seu CEP"}
              erro={inscricaoDB ? (erros.cep = "") : erros.cep}
              onChange={(e) => this.onChange("cep", e.target.value)}
            />
          </div>

          <div className="Form-Inscricao-Container flex horizontal">
            <InputSimples
              id={"rua"}
              label="Logradouro"
              erro={inscricaoDB ? (erros.logradouro = "") : erros.logradouro}
              value={inscricaoDB ? inscricaoDB.logradouro : logradouro}
              onChange={(e) => this.onChange("logradouro", e.target.value)}
            />
            <InputSimples
              label="Número"
              value={inscricaoDB ? inscricaoDB.numero : numero}
              erro={inscricaoDB ? (erros.numero = "") : erros.numero}
              helpText={"Preencha o numero da sua casa"}
              onChange={(e) => this.onChange("numero", e.target.value)}
            />
            <InputSimples
              label="Complemento"
              value={inscricaoDB ? inscricaoDB.complemento : complemento}
              onChange={(e) => this.onChange("complemento", e.target.value)}
            />
          </div>

          <div className="Form-Inscricao-Container flex horizontal">
            <InputSimples
              id={"bairro"}
              label="Bairro"
              value={inscricaoDB ? inscricaoDB.bairro : this.state.bairro}
              erro={erros.bairro}
            />
            <InputSimples
              label="Cidade"
              id={"cidade"}
              value={inscricaoDB ? inscricaoDB.cidade : this.state.cidade}
              erro={erros.cidade}
            />
            <InputSimples
              label="Estado"
              id={"uf"}
              value={inscricaoDB ? inscricaoDB.uf : this.state.uf}
              erro={erros.uf}
            />
          </div>

          <div className="Form-Inscricao-Container flex horizontal">
            <InputSimples
              label="E-mail"
              value={inscricaoDB ? inscricaoDB.email : email}
              erro={inscricaoDB ? (erros.email = "") : erros.email}
              helpText={"Preencha seu E-mail"}
              onChange={(e) => this.onChange("email", e.target.value)}
            />
            <InputSimples
              label="Telefone"
              id="telefone"
              value={inscricaoDB ? inscricaoDB.telefone : telefone}
              helpText={"Preencha seu telefone"}
              erro={inscricaoDB ? (erros.telefone = "") : erros.telefone}
              onChange={(e) => this.onChange("telefone", e.target.value)}
            />
            <SelectSimples
              className="Select-Simples"
              label="Cargo"
              value={inscricaoDB ? inscricaoDB.cargo : cargo}
              name={"cargo"}
              opcoes={[
                ...(cargos || []).map((item) => ({
                  label: item.nome,
                  value: item.value,
                })),
              ]}
              disabled={"false"}
              helpText={"Preencha seu cargo"}
              erro={inscricaoDB ? (erros.cargo = "") : erros.cargo}
              onChange={(e) => this.onChange("cargo", e.target.value)}
            />
          </div>

          {this.state.ok === false && !inscricaoDB && (
            <Button
              label={"Salvar"}
              type={"inscricao"}
              onClick={handleSubmit(this.state)}
            />
          )}

          <hr />
        </div>
        {this.state.finalizado === true && (
          <div className="Container-Btn-Print">
            <button onClick={() => window.print()}>
              Imprimir&nbsp;&nbsp;
              <i class="fa fa-print" aria-hidden="true"></i>
            </button>
          </div>
        )}
        {this.state.finalizado === true && (
          <ModalSimples
            titulo="Inscrição finalizada"
            descricao={`Sua inscrição foi finalizada com sucesso! Esse é seu protocolo para futuras consultas:`}
            inscricao={inscricaoDB ? inscricaoDB : this.state.inscricao}
            file={this.state.nome_file}
          />
        )}
        <footer>
          <Footer />
        </footer>
      </div>
    )
  }
}
